import React, { useState } from 'react';

/* eslint-disable jsx-a11y/alt-text */
const Desktop = () => {

	const [active, setActive] = useState('all');

	return (
		<>
			<div className="filter-categories">
				<ul className="project-filter">
					<li className="filter" data-filter="all" onClick={setActive.bind(this, 'all')}><span>Sve</span></li>
					<li className="filter" data-filter="living_room" onClick={setActive.bind(this, 'living_room')}><span>Dnevna Soba</span></li>
					<li className="filter" data-filter="kitchen" onClick={setActive.bind(this, 'kitchen')}><span>Kuhinja</span></li>
					<li className="filter" data-filter="dinning_room" onClick={setActive.bind(this, 'dinning_room')}><span>Trpezarija</span></li>
					<li className="filter" data-filter="bedroom" onClick={setActive.bind(this, 'bedroom')}><span>Spavaća soba</span></li>
					<li className="filter" data-filter="bathroom" onClick={setActive.bind(this, 'bathroom')}><span>Kupatilo</span></li>
					<li className="filter" data-filter="wellness" onClick={setActive.bind(this, 'wellness')}><span>Wellness</span></li>
					<li className="filter" data-filter="restoraunt" onClick={setActive.bind(this, 'restoraunt')}><span>Restoran</span></li>
				</ul>
			</div>
			<div className="projects-holder">
				<div className="row text-center">

					{/* {{--    LIVING ROOM    --}} */}

					<div className="col-md-3 col-sm-6 project-item mix living_room">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/living_room/small/1.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/living_room/1.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'living_room' ? 'living_room' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix living_room">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/living_room/small/2.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/living_room/2.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'living_room' ? 'living_room' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix living_room">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/living_room/small/3.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/living_room/3.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'living_room' ? 'living_room' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix living_room">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/living_room/small/4.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/living_room/4.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'living_room' ? 'living_room' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix living_room">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/living_room/small/5.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/living_room/5.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'living_room' ? 'living_room' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix living_room">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/living_room/small/6.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/living_room/6.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'living_room' ? 'living_room' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>

					{/* {{--    KITCHEN    --}} */}

					<div className="col-md-3 col-sm-6 project-item mix kitchen">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/kitchen/small/1.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/kitchen/1.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'kitchen' ? 'kitchen' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					{/* <div className="col-md-3 col-sm-6 project-item mix kitchen">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/vila/kitchen/small/2.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/vila/kitchen/2.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'kitchen' ? 'kitchen' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div> */}

					{/* {{--    DINNING ROOM    --}} */}

					<div className="col-md-3 col-sm-6 project-item mix dinning_room">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/dinning_room/small/1.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/dinning_room/1.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'dinning_room' ? 'dinning_room' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix dinning_room">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/dinning_room/small/2.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/dinning_room/2.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'dinning_room' ? 'dinning_room' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>

					{/* {{--    BEDROOM    --}} */}

					<div className="col-md-3 col-sm-6 project-item mix bedroom">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/bedroom/small/1.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/bedroom/1.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'bedroom' ? 'bedroom' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix bedroom">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/bedroom/small/2.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/bedroom/2.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'bedroom' ? 'bedroom' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix bedroom">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/bedroom/small/3.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/bedroom/3.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'bedroom' ? 'bedroom' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>

					{/* {{--    BATHROOM    --}} */}

					<div className="col-md-3 col-sm-6 project-item mix bathroom">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/bathroom/small/1.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/bathroom/1.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'bathroom' ? 'bathroom' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>

					{/* {{--    WELLNESS    --}} */}

					<div className="col-md-3 col-sm-6 project-item mix wellness">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/wellness/small/1.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/wellness/1.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'wellness' ? 'wellness' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix wellness">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/wellness/small/2.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/wellness/2.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'wellness' ? 'wellness' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix wellness">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/wellness/small/3.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/wellness/3.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'wellness' ? 'wellness' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix wellness">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/wellness/small/4.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/wellness/4.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'wellness' ? 'wellness' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix wellness">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/wellness/small/5.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/wellness/5.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'wellness' ? 'wellness' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>

					{/* {{--    RESTORAUNT    --}} */}

					<div className="col-md-3 col-sm-6 project-item mix restoraunt">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/restoraunt/small/1.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/restoraunt/1.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'restoraunt' ? 'restoraunt' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 project-item mix restoraunt">
						<div className="thumb">
							<div className="image">
								<img src={require('../../resources/img/apartment/restoraunt/small/2.jpg').default} />
							</div>
							<div className="hover-effect">
								<a href={require('../../resources/img/apartment/restoraunt/2.jpg').default} data-lightbox={active === 'all' ? 'all' : (active === 'restoraunt' ? 'restoraunt' : '')}>
									<i className="fa fa-search"></i>
								</a>
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	)
}

export default Desktop;