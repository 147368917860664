/* eslint-disable jsx-a11y/alt-text */
const Mobile = () => {
	return (
		<div id="mobile_scroller" class="content mThumbnailScroller">
			<ul>

				{/* {{--    LIVING ROOM    --}} */}

				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/living_room/1.jpg').default}>
						<img src={require('../../resources/img/apartment/living_room/small/1.jpg').default} />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/living_room/2.jpg').default}>
						<img src={require('../../resources/img/apartment/living_room/small/2.jpg').default} />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/living_room/3.jpg').default}>
						<img src={require('../../resources/img/apartment/living_room/small/3.jpg').default} />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/living_room/4.jpg').default}>
						<img src={require('../../resources/img/apartment/living_room/small/4.jpg').default} />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/living_room/5.jpg').default}>
						<img src={require('../../resources/img/apartment/living_room/small/5.jpg').default} />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/living_room/6.jpg').default}>
						<img src={require('../../resources/img/apartment/living_room/small/6.jpg').default} />
					</a>
				</li>

				{/* {{--    KITCHEN    --}} */}

				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/kitchen/1.jpg').default}>
						<img src={require('../../resources/img/apartment/kitchen/small/1.jpg').default} />
					</a>
				</li>

				{/* {{--    DINNING ROOM    --}} */}

				<li>
					<a class="swipebox" href="/img/apartment/dinning_room/1.jpg">
						<img src="/img/apartment/dinning_room/small/1.jpg" />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/dinning_room/2.jpg').default}>
						<img src={require('../../resources/img/apartment/dinning_room/small/2.jpg').default} />
					</a>
				</li>

				{/* {{--    BEDROOM    --}} */}

				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/bedroom/1.jpg').default}>
						<img src={require('../../resources/img/apartment/bedroom/small/1.jpg').default} />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/bedroom/2.jpg').default}>
						<img src={require('../../resources/img/apartment/bedroom/small/2.jpg').default} />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/bedroom/3.jpg').default}>
						<img src={require('../../resources/img/apartment/bedroom/small/3.jpg').default} />
					</a>
				</li>

				{/* {{--    BATHROOM    --}} */}

				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/bathroom/1.jpg').default}>
						<img src={require('../../resources/img/apartment/bathroom/small/1.jpg').default} />
					</a>
				</li>

				{/* {{--    WELLNESS    --}} */}

				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/wellness/1.jpg').default}>
						<img src={require('../../resources/img/apartment/wellness/small/1.jpg').default} />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/wellness/2.jpg').default}>
						<img src={require('../../resources/img/apartment/wellness/small/2.jpg').default} />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/wellness/3.jpg').default}>
						<img src={require('../../resources/img/apartment/wellness/small/3.jpg').default} />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/wellness/4.jpg').default}>
						<img src={require('../../resources/img/apartment/wellness/small/4.jpg').default} />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/wellness/5.jpg').default}>
						<img src={require('../../resources/img/apartment/wellness/small/5.jpg').default} />
					</a>
				</li>

				{/* {{--    RESTORAUNT    --}} */}

				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/restoraunt/1.jpg').default}>
						<img src={require('../../resources/img/apartment/restoraunt/small/1.jpg').default} />
					</a>
				</li>
				<li>
					<a class="swipebox" href={require('../../resources/img/apartment/restoraunt/2.jpg').default}>
						<img src={require('../../resources/img/apartment/restoraunt/small/2.jpg').default} />
					</a>
				</li>
			</ul>
		</div>
	);
}

export default Mobile;