import About from "./components/layout/About";
import AboutComplex from "./components/layout/AboutComplex";
import FunFacts from "./components/layout/FunFacts";
import Gallery from "./components/layout/Gallery";
import Header from "./components/layout/Header";
import Slider from "./components/layout/Slider";
import Activities from "./components/layout/Activities";
import Contact from "./components/layout/Contact";
import Footer from "./components/layout/Footer";
import { useEffect } from "react";

import $ from 'jquery';

import './App.scss';

const App = () => {

	const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

	// scroll function
	const scrollToID = (id, speed) => {
		let offSet = 50;
		let targetOffset = $(id).offset().top - offSet;
		let mainNav = $('#main-nav');
		$('html,body').animate({ scrollTop: targetOffset }, speed);
		if (mainNav.hasClass("open")) {
			mainNav.css("height", "1px").removeClass("in").addClass("collapse");
			mainNav.removeClass("open");
		}
	}

	useEffect(() => {
		// navigation click actions
		$('.scroll-link').on('click', function (event) {
			event.preventDefault();
			var sectionID = $(this).attr("data-id");
			scrollToID('#' + sectionID, 750);
		});

		// scroll to top action
		$('.scroll-top').on('click', function (event) {
			event.preventDefault();
			$('html, body').animate({ scrollTop: 0 }, 'slow');
		});

		// mobile nav toggle
		$('#nav-toggle').on('click', function (event) {
			event.preventDefault();
			$('#main-nav').toggleClass("open");
		});

		// submit contact form
		$('#contact_form').on('submit', function (e) {
			e.preventDefault();
			var $data = new FormData(this);
			var $alert = '';
			for (var $d of $data.entries()) {
				$alert += $d[0] + ' => ' + $d[1] + '\n';
			}
			alert($alert);
		});
	});

	return (
		<>
			<Header />

			<Slider />

			<FunFacts />

			<About />

			<Gallery isMobile={isMobile} />

			<AboutComplex />

			<Activities />

			<Contact />

			<Footer />
		</>
	);
}

export default App;
