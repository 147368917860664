/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Desktop from '../gallery/Desktop';
import Mobile from '../gallery/Mobile';

const Gallery = (props) => {

	return (
		<div id="vila_gallery" className="page-section">
			<div className="content-wrapper">
				<div className="inner-container container">
					<div className="row">
						<div className="col-md-12">
							<div className="section-heading">
								<h4>Galerija</h4>
								<div className="line-dec"></div>
							</div>
						</div>
					</div>
					<div className="projects-holder-3">
						{
							props.isMobile ? <Mobile /> : <Desktop />
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Gallery;