/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Header = () => {
	return (
		<header className="header">
			<div className="container">
				<nav className="navbar navbar-inverse" role="navigation">
					<div className="navbar-header">
						<button type="button" id="nav-toggle" className="navbar-toggle" data-toggle="collapse"
							data-target="#main-nav">
							<span className="sr-only">Toggle navigation</span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
							<span className="icon-bar"></span>
						</button>
						<a href="#" className="navbar-brand scroll-top">
							<div className="logo"></div>
						</a>
					</div>

					<div id="main-nav" className="collapse navbar-collapse">
						<ul className="nav navbar-nav">
							<li><a href="#" className="scroll-link" data-id="about_vila">Bond apartman</a></li>
							<li><a href="#" className="scroll-link" data-id="about_complex">Forest</a></li>
							<li><a href="#" className="scroll-link" data-id="activity">Aktivnosti</a></li>
							<li><a href="#" className="scroll-link" data-id="contact">Kontakt</a></li>
						</ul>
					</div>
				</nav>
			</div>
		</header>
	)
}

export default Header;