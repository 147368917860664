import React from 'react';

const AboutComplex = () => {
	return (
		<div id="about_complex" className="page-section">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="section-heading">
							<h4>O Forest apartmanima</h4>
							<div className="line-dec"></div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="left-text">
							<p>
								"Forest" se nalazi u starom delu Zlatibora, u naselju Sloboda, u blizini Palisada, okružen netaknutom prirodom, izolovan od svakodnevne gužve, a na samo 1.5 km od centra.<br /><br />
								Posebna pažnja posvećena je uređenju dvorišta, prostor sa dosta zelenila, cvetnih aranžmana, letnjikovcem pokraj koga protiče potok i daje posebno obeležje.<br /><br />
								Projektovano po svim standardima, korišćeni najkvalitetniji i najsavremeniji materijali. Fasada se uklapa svojim izgledom u Zlatiborski stil, izrađena u kombinaciji akrilnih maltera, drvenom oblogom i kamenom, zadovoljava sve energetske standarde.<br /><br />
								U okviru zgrade se nalaze restoran i wellness centar, sa specijalnim pogodnostima za goste apartmana. Za najmlađe je obezbeđeno dečije igralište.<br /><br />
								U krugu od 200m nalaze se mega market, mesara i dečija igraonica.
							</p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="right-image">
							<img src={require('../../resources/img/about_forest.png').default} alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutComplex;