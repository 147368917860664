/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const About = () => {
	return (
		<div id="about_vila">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="section-heading">
							<h4>O Bond apartmanu</h4>
							<div className="line-dec"></div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="left-text">
							<img className="about-vila-img" src={require('../../resources/img/logo/logo256.png').default} alt="" align="left" hspace={50} vspace={50} />
							<br clear="right" />
							<p>Bond apartman deo je apartmanskog kompleksa <a href="#" className="scroll-link" data-id="about_complex">"Forest"</a>.<br />
                        Apartman je ukupne površine 37.5 kvadratnih metara koji poseduje:<br />
						- spavaću sobu sa francuskim ležajem<br />
                        - prostrani dnevni boravak<br />
						- odvojenu trpezariju<br />
                        - opremljenu modernu kuhinju<br />
                        - kupatilo<br />
                        Etažno grejanje, kao i podno grejanje u
                        kupatilu i hodniku, učiniće vaš
                        odmor toplim i ušuškanim utočištem od svakodnevnih obaveza.
                    </p>
							<ul>
								<li>
									<div className="white-button">
										<a href="#" className="scroll-link" data-id="vila_gallery">Galerija</a>
									</div>
								</li>
								<li>
									<div className="primary-button">
										<a href="#" className="scroll-link" data-id="about_complex">Forest</a>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div className="col-md-6">
						<div className="right-image">
							<img src={require('../../resources/img/about_apartment.png').default} alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default About;