import React from 'react';

const Activities = () => {
	return (
		<div id="activity" className="page-section">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="section-heading">
							<h4>Aktivnosti na Zlatiboru</h4>
							<div className="line-dec"></div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-12">
								<div className="blog-item b1">
									<div className="thumb">
										<img src={require('../../resources/img/activity/kvadovi.jpg').default} alt="" />
										<div className="text-content">
											<h4>Kvadovi (Off-Road)</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-12">
								<div className="blog-item second-blog b2">
									<div className="thumb">
										<img src={require('../../resources/img/activity/paraglajding.jpg').default} alt="" />
										<div className="text-content">
											<h4>Paraglajding</h4>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="blog-item b3">
									<div className="thumb">
										<img src={require('../../resources/img/activity/gondola.jpg').default} alt="" />
										<div className="text-content">
											<h4>Vožnja gondolom</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-6">
						<div className="row">
							<div className="col-md-12">
								<div className="blog-item second-blog b2">
									<div className="thumb">
										<img src={require('../../resources/img/activity/jahanje.jpg').default} alt="" />
										<div className="text-content">
											<h4>Jahanje</h4></div>
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="blog-item b3">
									<div className="thumb">
										<img src={require('../../resources/img/activity/zipline.jpg').default} alt="" />
										<div className="text-content">
											<h4>Zip-Line</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-12">
								<div className="blog-item b1">
									<div className="thumb">
										<img src={require('../../resources/img/activity/skijanje.jpg').default} alt="" />
										<div className="text-content">
											<h4>Skijanje</h4>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Activities;