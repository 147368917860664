/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const FunFacts = () => {
	return (
		<div id="fun-facts">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="section-heading">
							<h4>Udaljenost Bond apartmana</h4>
							<div className="line-dec"></div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-3 col-sm-6 col-xs-12">
						<div className="fact-item">
							<div className="counter" data-count="220">0 km</div>
							<span>Beograd</span>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 col-xs-12">
						<div className="fact-item">
							<div className="counter" data-count="290">0 km</div>
							<span>Novi Sad</span>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 col-xs-12">
						<div className="fact-item">
							<div className="counter" data-count="130">0 km</div>
							<span>Kragujevac</span>
						</div>
					</div>
					<div className="col-md-3 col-sm-6 col-xs-12">
						<div className="fact-item">
							<div className="counter" data-count="285">0 km</div>
							<span>Niš</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FunFacts;