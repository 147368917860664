import React from 'react';

const Contact = () => {
	return (
		<div id="contact" className="page-section">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="section-heading">
							<h4>Kontakt</h4>
							<div className="line-dec"></div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						<iframe
							title="map"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2882.897668925531!2d19.706577865832493!3d43.73345262911871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47582fc2d48f1e21%3A0x9e39e46f77b7b190!2sBond%20apartment%20Zlatibor!5e0!3m2!1ssr!2srs!4v1611018472151!5m2!1ssr!2srs"
							width="100%"
							height="320"
							frameBorder="0"
							style={{ border: 0 }}
							allowFullScreen
							aria-hidden="false"
							tabIndex="0">
						</iframe>
					</div>
					<div className="col-md-6">
						<table className="contact-table">
							<tbody>
								<tr>
									<td>Adresa:</td>
									<td>
										<a href="https://goo.gl/maps/oCBiGD1ps9WFxopM9" target="_blank" rel="noreferrer">
											Ćaldov put 44, Zlatibor
										</a>
									</td>
								</tr>
								<tr>
									<td>Telefon:</td>
									<td>
										<a href="tel:+381644201445">+381 64 420 14 45</a>
									</td>
								</tr>
								<tr>
									<td>Email:</td>
									<td>
										<a href="mailto:bondapartmant@gmail.com">bondapartmant@gmail.com</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Contact;