/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

const Slider = () => {
	return (
		<section className="cd-hero">
			<video autoPlay muted loop playsInline id="background-video">
				<source src={require('../../resources/video/promo.webm').default} type="video/webm" />
				<source src={require('../../resources/video/promo.mp4').default} type="video/mp4" />
					Your browser does not support HTML5 video.
			</video>
			<div className="video-overlay"></div>
			<ul className="cd-hero-slider autoplay">

				<li className="selected first-slide">
					<div className="cd-full-width">
						<div className="tm-slide-content-div slide-caption">
							<h2>Bond Apartman</h2>
							<p>Bond apartman deo je apartmanskog kompleksa "Forest" koji će vam svojom toplinom i kvalitetom pružiti
                        nezaboravan odmor na Zlatiboru.<br />Dođite i uverite se u naš kvalitet.</p>
							<div className="primary-button">
								<a href="#" className="scroll-link" data-id="about_vila">Detaljnije</a>
							</div>
						</div>
					</div>
				</li>

				<li className="second-slide">
					<div className="cd-full-width">
						<div className="tm-slide-content-div slide-caption">
							<h2>Forest apartmani</h2>
							<p>Apartmanski kompleks "Forest" nalazi se u naselju Sloboda u blizini Palisada.<br />
							Kvalitet gradnje, sadržaj, kao i miran kraj, svrstava "Forest" medju najpoželjnije apartmane na Zlatiboru.
							</p>
							<div className="primary-button">
								<a href="#" className="scroll-link" data-id="about_complex">Detaljnije</a>
							</div>
						</div>
					</div>
				</li>

				<li className="third-slide">
					<div className="cd-full-width">
						<div className="tm-slide-content-div slide-caption">
							<h2>Aktivnosti</h2>
							{/* <p>
								Na Zlatiboru ima brojnih aktivnosti kako zimi, tako i leti.<br/>Najpopularnije je
                        		skijanje, čija poseta ovoj planini iz godine u godinu obara rekorde posećenosti.
							</p> */}
							<p>
								Na Zlatiboru ima brojnih aktivnosti kako zimi, tako i leti.<br />
								Među najpopularnijim su vožnja kvadovima, offroad avanture i vožnja gondolom,
								čija poseta ovoj planini iz godine u godinu obara rekorde posećenosti.
							</p>
							<div className="primary-button">
								<a href="#" className="scroll-link" data-id="activity">Detaljnije</a>
							</div>
						</div>
					</div>
				</li>
			</ul>

			<div className="cd-slider-nav">
				<nav>
					<span className="cd-marker item-1"></span>

					<ul>
						<li className="selected"><a href="#0"></a></li>
						<li><a href="#0"></a></li>
						<li><a href="#0"></a></li>
					</ul>
				</nav>
			</div>
		</section>
	)
}

export default Slider;