/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Footer = () => {
	return (
		<footer>
			<div className="container">
				<div className="row">
					<div className="col-md-6">
						<div className="copyright-text">
							<p>
								Copyright &copy; {(new Date()).getFullYear()} <b>Bond apartman</b> | Design: <a href="http://www.tooplate.com"
									target="_blank" rel="noreferrer">Tooplate</a>
							</p>
						</div>
					</div>
					<div className="col-md-6">
						<div className="social-icons">
							<ul>
								<li>
									<a href="https://www.facebook.com/bondzlatibor" target="_blank" rel="noreferrer">
										<i className="fa fa-facebook"></i>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/zlatibor_bond_apartment/" target="_blank" rel="noreferrer">
										<i className="fa fa-instagram"></i>
									</a>
								</li>
								<li>
									<a href="https://www.booking.com/hotel/rs/bond-apartment.sr.html" target="_blank" rel="noreferrer">
										<strong className="booking-icon">B.</strong>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer;